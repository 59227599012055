import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Card, Checkbox, Col, Form, Radio, Row, Select, Space, Spin, Table, message, Tooltip, Popconfirm, Image } from 'antd';
import SignaturePad from 'react-signature-canvas';
import html2pdf from 'html2pdf.js';
import { fetchAdminApi } from '../../services/api';
import constants from '../../constants/constants';
import {
    template0, template1, template2, template4, template6, template9, template10, template11, template12, template14, template16, template17, template23, template27, template28, luscherColorTest
} from '../../globalFunctions/GlobalFunctions';
import TextArea from 'antd/es/input/TextArea';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import Logo from '../../assets/roy_logo.png'

const MasterWbList = () => {
    const location = useLocation();
    const history = useHistory();
    const [loader, setLoader] = useState(true);
    const sectionId = location.state?.sectionId;
    const sectionType = location.state?.sectionType;
    const sectionName = location.state?.sectionName;
    const associateId = location.state?.associateId;
    const associateName = location.state?.associateName;
    const plan = location.state?.isScholarship;
    const duration = location.state?.duration;
    const docName = location.state?.docName;
    const header = location.state?.header;
    const [sectionData, setSectionData] = useState([]);
    const [tableCheckboxValue, setTableCheckboxValue] = useState([]);
    const sigPad = useRef(null);
    const [esignValue, setEsignValue] = useState("");
    const agreementValue = JSON.parse(sessionStorage.getItem('agreementValue'));
    const [tableData] = useState(
        {
            mylisttable1: [
                {
                    key: 1,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    reaction: ``,
                    table: "AngerSheetTable",
                    futureAction: ``,
                    is_pushed: 0
                },
                {
                    key: 2,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    futureAction: ``,
                    reaction: ``,
                    table: "AngerSheetTable",
                    is_pushed: 0
                },
                {
                    key: 3,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    futureAction: ``,
                    reaction: ``,
                    table: "AngerSheetTable",
                    is_pushed: 0
                },
                {
                    key: 4,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    reaction: ``,
                    futureAction: ``,
                    table: "AngerSheetTable",
                    is_pushed: 0
                },
                {
                    key: 5,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    futureAction: ``,
                    reaction: ``,
                    table: "AngerSheetTable",
                    is_pushed: 0
                }
            ],
            mylisttable2: [
                {
                    key: 1,
                    name: ``,
                    fearCause: ``,
                    fearAction: ``,
                    table: "FearTable",
                    is_pushed: 0
                },
                {
                    key: 2,
                    name: ``,
                    fearCause: ``,
                    fearAction: ``,
                    table: "FearTable",
                    is_pushed: 0
                },
                {
                    key: 3,
                    name: ``,
                    fearCause: ``,
                    fearAction: ``,
                    table: "FearTable",
                    is_pushed: 0
                },
                {
                    key: 4,
                    name: ``,
                    fearCause: ``,
                    fearAction: ``,
                    table: "FearTable",
                    is_pushed: 0
                },
                {
                    key: 5,
                    name: ``,
                    fearCause: ``,
                    fearAction: ``,
                    table: "FearTable",
                    is_pushed: 0
                }
            ],
            tablecode: [
                {
                    key: 1,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    table: "tableCode",
                    is_pushed: 0
                },
                {
                    key: 2,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    table: "tableCode",
                    is_pushed: 0
                },
                {
                    key: 3,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    table: "tableCode",
                    is_pushed: 0
                },
                {
                    key: 4,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    table: "tableCode",
                    is_pushed: 0
                },
                {
                    key: 5,
                    name: ``,
                    problem: ``,
                    affected: ``,
                    table: "tableCode",
                    is_pushed: 0
                }
            ]
        }
    )
    //lusher color test
    const colors = [
        "rgb(255, 51, 0)", /*Orange-Red color*/
        "rgb(255, 204, 0)", /*Bright Yellow color*/
        "rgb(0, 84, 71)", /*Blue-Green color*/
        "rgb(0, 0, 85)", /*Dark Blue color*/
        "rgb(153, 51, 0)", /*Brown color*/
        "rgb(153, 153, 153)", /*Grey color*/
        "rgb(0, 0, 0)", /*Black color*/
        "rgb(153, 0, 102)" /*Violet color*/
    ];
    const [boxes, setBoxes] = useState([
        {
            id: 1,
            color: ""
        },
        {
            id: 2,
            color: ""
        },
        {
            id: 3,
            color: ""
        },
        {
            id: 4,
            color: ""
        },
        {
            id: 5,
            color: ""
        },
        {
            id: 6,
            color: ""
        },
        {
            id: 7,
            color: ""
        },
        {
            id: 8,
            color: ""
        },
    ]);
    const updateBoxColors = () => {
        //genereting different colors
        const shuffledColors = colors.sort(() => 0.5 - Math.random());
        const initialBoxes = shuffledColors.map((color, index) => {
            return {
                id: index + 1,
                color: color,
                visible: true
            };
        });
        setBoxes(initialBoxes);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                let endpoint = '/master-workbook';
                let payload = {};

                if (agreementValue) {
                    endpoint = '/coaching-agreement';
                    payload = {
                        is_scholarship: agreementValue?.is_scholarship,
                        associate_id: agreementValue?.associate_id,
                        duration: agreementValue?.agreement_duration,
                        amount: agreementValue?.amount,
                        currency: agreementValue?.currency,
                        show_family: agreementValue?.show_family,
                        show_psych: agreementValue?.show_psych,
                    };
                } else {
                    if (sectionId === "0") {
                        if (sectionType === "") {
                            payload = {
                                section: sectionId,
                                type: sectionType,
                                associate_id: associateId,
                                is_scholarship: plan,
                                duration: duration
                            };
                        } else {
                            payload = { section: sectionId, type: sectionType };
                        }
                    } else {
                        payload = { section: sectionId, section_type: sectionType };
                    }
                }

                const res = await fetchAdminApi(endpoint, 'post', payload);

                if (res?.code === 200) {
                    const combinedData = sectionId === "0" ?
                        res.data.flatMap(itemArray => itemArray) :
                        Object.values(res.data).flat();

                    setSectionData(combinedData);
                } else {
                    message.error(res?.message);
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                setLoader(false);
            }
        };

        fetchData();
    }, [sectionId, sectionType, associateId, plan, duration]);

    useEffect(() => {
        const handleDownloadClick = async () => {
            try {
                setLoader(true);
                const downloadContent = document.getElementById('downloadContent');
                const group1 = document.getElementById('group1');
                const group2 = document.getElementById('group2');
                const group3 = document.getElementById('group3');
                const element = {
                    margin: [25, 20],
                    filename: filename,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 1, letterRendering: true },
                    jsPDF: { unit: 'pt', format: 'a3', orientation: 'portrait' },
                    pagebreak: {
                        mode: ['avoid-all', 'css', 'legacy'],
                        // avoid: ['table', 'img', 'div.repeatData'],
                    },
                };

                if (sectionId !== "2") {
                    await html2pdf().from(downloadContent).set(element).save();
                } else {
                    if (group1) {
                        const filename1 = `${constants.sec}-${sectionId}-Part-1.pdf`;
                        await html2pdf().from(group1).set({ ...element, filename: filename1 }).save();
                    }
                    if (group2) {
                        const filename2 = `${constants.sec}-${sectionId}-Part-2.pdf`;
                        await html2pdf().from(group2).set({ ...element, filename: filename2 }).save();
                    }
                    if (group3) {
                        const filename3 = `${constants.sec}-${sectionId}-Part-3.pdf`;
                        await html2pdf().from(group3).set({ ...element, filename: filename3 }).save();
                    }
                }
            } catch (error) {
                message.error("Error generating PDF");
                console.log(error);
            } finally {
                setLoader(false);
            }
        };

        const downloadButton = document.querySelectorAll('.downloadPdf');
        downloadButton.forEach((button) => {
            button.addEventListener('click', handleDownloadClick);
        });
        updateBoxColors();

        return () => {
            downloadButton.forEach((button) => {
                button.removeEventListener('click', handleDownloadClick);
            });
        };
    }, [sectionId, sectionType]);

    const agreementLabel = `${associateName}-${header}-${sectionName}`;
    let filename;
    if (sectionId === "0") {
        if (sectionType === "") {
            filename = `${agreementLabel}.pdf`;
        } else {
            filename = `${sectionName}.pdf`;
        }
    } else if (sectionId === "7") {
        filename = `${sectionName}.pdf`;
    } else if (sectionId !== "2") {
        filename = `${constants.sec}-${sectionId}.pdf`;
    }

    const template3 = (question, type_left) => {
        const rowValue = type_left === "TA" ? 8 : 1;

        return (
            <div className='py-2 labelInput'>
                <Form.Item
                    className="mb-0"
                    label={question ? <div dangerouslySetInnerHTML={{ __html: question }} className='text-start whitespace-normal self-end mb-2' /> : null}
                >
                    <TextArea
                        readOnly={true}
                        className="w-full pt-2"
                        autoSize={{ minRows: 8 }}
                    />
                </Form.Item>
            </div>
        );
    };

    const template5 = (label, data) => {
        return (
            <Col span={24}>
                <Form.Item
                    name="template5">
                    <Row gutter={[8, 8]} className='pt-2'>
                        <Col xs={24} lg={6}>
                            <Checkbox >{<div dangerouslySetInnerHTML={{ __html: label }} />}</Checkbox>
                        </Col>
                        <Col xs={24} lg={18} className='mt-2'>
                            <TextArea
                                readOnly={true}
                                status={data?.isEdited ? "error" : null}
                                autoSize={{ minRows: 6 }}
                            />
                        </Col>
                    </Row>
                </Form.Item>
            </Col>
        );
    };

    const template7 = (contentLeft) => {
        return (
            <div className='flex table-bordered'>
                <div className='w-4/5 p-3 border-black border-l border-t font-semibold'>{contentLeft}</div>
                <div className="w-1/5 text-center p-3 border-black border-x border-t">
                    <Radio.Group>
                        <Radio id="yes" value={"1"}>Yes</Radio>
                        <Radio id="no" value={"2"}>No</Radio>
                    </Radio.Group>
                </div>
            </div>
        );
    };

    const template8 = (contentLeft, data) => {
        let cssClass = null;
        if (data?.isEdited) {
            cssClass = 'w-full disabled-outline';
        } else {
            cssClass = 'w-full';
        }
        return (
            <div className='flex table-bordered w-6/12 mx-auto'>
                <div className='w-3/5 p-3 border-black border-l-2 border-t-2'>{contentLeft}</div>
                <div className='w-2/5 p-3 border-black border-x-2 border-t-2'>
                    <Select
                        status={data?.isEdited ? "error" : null}
                        showSearch
                        className={cssClass}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option.label.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.label.toLowerCase().indexOf(input.toLowerCase()) < 1;
                        }}
                        options={[
                            {
                                value: "1",
                                label: 'Never',
                            },
                            {
                                value: "2",
                                label: 'Once in a great while',
                            },
                            {
                                value: "3",
                                label: 'Rarely',
                            },
                            {
                                value: "4",
                                label: 'Sometimes',
                            },
                            {
                                value: "5",
                                label: 'Often',
                            },
                            {
                                value: "6",
                                label: 'Usually',
                            },
                            {
                                value: "7",
                                label: 'Always',
                            },
                        ]}
                    />
                </div>
            </div>
        );
    };

    const template13 = (content_left, data) => {
        const isChecked = data && data.check_box === 't';
        const onChangeCheckboxValue = (e) => {
        };

        return (
            <div className='py-2'>
                {data?.isEdited ? <div className="mr-4"><CheckCircleTwoTone /></div> : null}
                <Checkbox checked={isChecked} onChange={e => onChangeCheckboxValue(e)} id={data?.item_id}>{<div dangerouslySetInnerHTML={{ __html: content_left }} />}</Checkbox>
            </div>
        );
    };

    const template15 = (values, disable) => {
        const tableOneColumns = [
            {
                title: 'How can I respond better?',
                dataIndex: 'futureAction',
                width: '50%',
                editable: false,
                render: (futureAction) => {
                    return Array.isArray(futureAction) ? futureAction.join(", ") : futureAction;
                }
            },
        ];
        const column = tableOneColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    tableName: values.content_left === "My List Table" ? "Mylist" : "Mylist2",
                    disable: disable,
                }),
            };
        });
        const tableTwoColumns = [
            // section 4 table starts here
            {
                title: 'Name',
                dataIndex: 'name',
                width: '10%',
                editable: true,
                hidden: values?.content_left === "Anger Sheet table 2",
            },
            {
                title: "What they did to me?",
                dataIndex: "problem",
                editable: true,
                width: "20%",
                hidden: values?.content_left === "Anger Sheet table 2",
            },
            // section 5 table starts here
            {
                title: "Who Or what do i fear?",
                dataIndex: "name",
                editable: true,
                width: "20%",
                hidden: values?.content_left === "Anger Sheet table",
            },
            {
                title: "Why do i have this fear?",
                dataIndex: "fearCause",
                editable: true,
                width: "20%",
                hidden: values?.content_left === "Anger Sheet table",
            },
            // common column
            {
                title: "Which Instinct was affected?",
                dataIndex: "affected",
                width: "20%",
                // hidden: values.content_left === "Anger Sheet table 2" ? true : false,
                render: (_, record) => {
                    return <Select
                        defaultValue={record.affected ? record.affected : []}
                        mode="multiple"
                        allowClear
                        className="w-full"
                        options={[
                            {
                                label: "Sex",
                                options: [
                                    {
                                        label: "Connections",
                                        value: "Connections"
                                    },
                                    {
                                        label: "Relationships",
                                        value: "Relationships"
                                    }
                                ]
                            },
                            {
                                label: "Social",
                                options: [
                                    {
                                        label: "Fame",
                                        value: "Fame"
                                    },
                                    {
                                        label: "Popularity",
                                        value: "Popularity"
                                    },
                                    {
                                        label: "Self-esteem",
                                        value: "Self-esteem"
                                    }
                                ]
                            },
                            {
                                label: "Survival",
                                options: [
                                    {
                                        label: "Jobs",
                                        value: "Jobs"
                                    },
                                    {
                                        label: "Money",
                                        value: "Money"
                                    },
                                    {
                                        label: "Power",
                                        value: "Power"
                                    }
                                ]
                            }
                        ]}
                    />;
                }
            },
            // section 4
            {
                title: "How did I react?",
                dataIndex: "reaction",
                editable: true,
                width: "20%",
                hidden: values?.content_left === "Anger Sheet table 2",
            },
            // section 4 table ends here
            // section 5 
            {
                title: "Are There any actions i took to cause Or cover the fear?",
                dataIndex: "fearAction",
                editable: true,
                width: "20%",
                hidden: values?.content_left === "Anger Sheet table",
            },
            // section 5 table ends here
            // common column 
            {
                title: "How can I respond better?",
                dataIndex: "futureAction",
                width: "22%",
                render: (_, record) => {
                    const staticOptions = values?.content_left === "Anger Sheet table"
                        ? constants.Sec4FutureOptions.slice().sort((a, b) => a.localeCompare(b)).map((item) => ({
                            label: item,
                            value: item,
                        }))
                        : constants.Sec5FutureOptions.slice().sort((a, b) => a.localeCompare(b)).map((item) => ({
                            label: item,
                            value: item,
                        }));

                    return (
                        <Select
                            defaultValue={record.futureAction ? record.futureAction : []}
                            mode="tags"
                            className="w-full"
                            options={staticOptions}
                        />
                    );
                }
            },
            {
                title: 'Action',
                dataIndex: 'action',
                align: "center",
                width: '10%',
                render: (_, record) => {
                    return <Space>
                        <Tooltip title={constants.Save}>
                            <Button type="link" icon={<SaveOutlined />} />
                        </Tooltip>
                        <Tooltip title={constants.Delete}>
                            <Popconfirm title="Sure to delete?">
                                <Button type="link" danger icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip >
                    </Space>;
                }
            },
        ].filter(item => !item.hidden);
        const tableTwoColumnsNoDrodown = [
            // section 6 table column starts here
            {
                title: 'My Flaws',
                dataIndex: 'flaws',
                editable: false,
            },
            {
                title: 'People or Institution we have hurt by our actions arising out of character flaws',
                dataIndex: 'name',
                width: '40%',
                editable: true,
            },
            {
                title: "What did we do or say to them?",
                dataIndex: "problem",
                editable: true,
                width: "25%",
            },
            {
                title: "How can we make this right?",
                dataIndex: "affected",
                editable: true,
                width: "25%",
            },
            {
                title: 'Action',
                dataIndex: 'action',
                align: "center",
                width: '10%',
                render: (_, record) => {
                    return <Space>
                        <Tooltip title={constants.Save}>
                            <Button type="link" icon={<SaveOutlined />} /></Tooltip>
                        <Tooltip title={constants.Delete}>
                            <Popconfirm title="Sure to delete?">
                                <Button type="link" danger icon={<DeleteOutlined />} />
                            </Popconfirm></Tooltip >
                    </Space>;
                }
            },
        ];
        const column2 = tableTwoColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    tableName: values.content_left === "Anger Sheet table" ? "AngerSheetTable" : "AngerSheetTable2",
                    disable: disable,
                }),
            };
        });
        const column3 = tableTwoColumnsNoDrodown.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    tableName: "tableCode",
                    disable: disable,
                }),
            };
        });
        return (
            <div>
                {
                    values.content_left === "Anger Sheet table" || values.content_left === "Anger Sheet table 2" || values.content_left === "table code" ?
                        <Table
                            scroll={{ x: 900 }}
                            rowClassName={() => 'editable-row'}
                            bordered
                            dataSource={values.content_left === "table code" ? tableData.tablecode : values.content_left === "My List Table" ? tableData.mylisttable1 : tableData.mylisttable2}
                            columns={values.content_left === "table code" ? column3 : column2}
                            pagination={false}
                            title={() => values?.isEdited ? <CheckCircleTwoTone /> : null}
                        /> : <div className="w-2/5 mx-auto">
                            <Table
                                rowClassName={() => 'editable-row'}
                                bordered
                                dataSource={tableData.mylisttable2}
                                columns={column}
                                pagination={false}
                                title={() => values?.isEdited ? <CheckCircleTwoTone /> : null}
                            /></div>}
            </div>
        );
    };

    const template18 = (values) => {
        if (tableCheckboxValue.length > 0) {
            if (tempArray.length === 0) {
                if (values.content_left === "table with checkboxes code?") {
                    let payload = null;
                    payload = {
                        item_id: values.item_id,
                        section_id: values.section_id
                    };
                    if (values?.is_discussed === 1) {
                        payload.is_changed = 1;
                    }
                    tempArray.push(payload);
                }
            }
        }
        const column = [
            {
                title: 'My List',
                dataIndex: 'mylist',
                width: '50%',
            },
        ];
        let datasource = [];
        if (values.content_left === "table checkbox value") {
            if (values.client_ans) {
                if (values.client_ans.length > 0) {
                    datasource.length = 0;
                    for (let i = 0; i < values.client_ans.length; i++) {
                        datasource.push({ mylist: values.client_ans[i] });
                    }
                }
            }
        }
        return (
            <>
                {values.content_left === "table with checkboxes code?" || values.content_left === "Anger Sheet table 3"?
                    <div className='xl:[overflow-wrap:anywhere] overflow-x-auto pt-2'>
                        <Checkbox.Group>
                            <table className="w-full blue-header customTable">
                                {values?.isEdited ? <caption className="text-left"><CheckCircleTwoTone /></caption> : null}
                                <thead>
                                    <tr>
                                        <th>General</th>
                                        <th>Spiritual</th>
                                        <th>Buddhism</th>
                                        <th>Christianity</th>
                                        <th>Hinduism</th>
                                        <th>Sikhism</th>
                                        <th>Islam</th>
                                    </tr>
                                    <tr>
                                        <th>The greatest defects of humans are</th>
                                        <th>Social Evils</th>
                                        <th>Obscurations</th>
                                        <th>Sins</th>
                                        <th>Skhandas</th>
                                        <th>Enemies of Man</th>
                                        <th>Nafs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Space direction="vertical">
                                                {constants.GeneralOptions.map((option) => {
                                                    return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>;
                                                })}
                                            </Space>
                                        </td>
                                        <td>
                                            <Space direction="vertical">
                                                {constants.SpiritualOptions.map((option) => {
                                                    return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>;
                                                })}
                                            </Space>
                                        </td>
                                        <td>
                                            <Space direction="vertical">
                                                {constants.BuddhismOptions.map((option) => {
                                                    return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>;
                                                })}
                                            </Space>
                                        </td>
                                        <td>
                                            <Space direction="vertical">
                                                {constants.ChristianityOptions.map((option) => {
                                                    return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>;
                                                })}
                                            </Space>
                                        </td>
                                        <td>
                                            <Space direction="vertical">
                                                {constants.HinduismOptions.map((option) => {
                                                    return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>;
                                                })}
                                            </Space>
                                        </td>
                                        <td>
                                            <Space direction="vertical">
                                                {constants.SikhismOptions.map((option) => {
                                                    return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>;
                                                })}
                                            </Space>
                                        </td>
                                        <td>
                                            <Space direction="vertical">
                                                {constants.IslamOptions.map((option) => {
                                                    return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>;
                                                })}
                                            </Space>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Checkbox.Group>
                    </div> :
                    <div className="w-2/5 mx-auto">
                        <Table
                            columns={column}
                            dataSource={datasource}
                            pagination={true}
                        ></Table>
                    </div>}
            </>
        );
    };

    const template19 = (content_left, data) => {
        return (
            <div>
                <div>
                    <Checkbox ><div dangerouslySetInnerHTML={{ __html: content_left }} /></Checkbox>
                </div>
                <div className='pl-6 mb-5 mt-2'>
                    <TextArea status={data?.isEdited ? "error" : null} readOnly={true} autoSize={true} rows={1} />
                </div>
            </div>
        );
    };

    const template20 = () => {
        const clear = () => {
            sigPad.current.clear();
            setEsignValue("");
        };
        function handleEnd() {
            // Get the data URL of the signature image
            const dataUrl = sigPad.current.toDataURL();
            setEsignValue(dataUrl);
        }
        return (
            <Card title={<h3 className='py-4'>Signature</h3>}>
                <div className="sigContainer">
                    <SignaturePad canvasProps={{ className: 'sigPad' }}
                        ref={sigPad}
                        onEnd={handleEnd}
                    />
                    <Tooltip title="Clear">
                        <Button type="text" danger className="absolute bottom-0 right-0 m-4" onClick={clear} icon={<DeleteOutlined />} />
                    </Tooltip>
                </div>
            </Card>
        );
    };

    const checkboxGrid = (content_left, data, position, disable) => {
        const isChecked = data?.check_box !== 'f';
        const onChangeCheckboxValue = (e) => {
        };
        return (
            <div className='py-2 mr-5 inline-block'>
                {data?.isEdited ? <div className="mr-4"><CheckCircleTwoTone /></div> : null}
                <Checkbox checked={isChecked} onChange={e => onChangeCheckboxValue(e)} id={data?.item_id}>{<div dangerouslySetInnerHTML={{ __html: content_left }} />}</Checkbox>
            </div>
        );
    };

    //checkbox with image.
    const template26 = (content_left, data, position, disable) => {
        const onChangeCheckboxValue = (e) => {
        };
        return (
            <div className='py-2 inline-block w-1/4 checkbox-start'>
                <Checkbox
                    onChange={e => onChangeCheckboxValue(e)}
                    id={data?.item_id}
                >
                    <Image
                        alt="img"
                        className='!w-60'
                        preview={false}
                        src={content_left}
                    />
                </Checkbox>
            </div>
        );
    };

    //template with checkbox and textarea for admin
    const template29 = (label, data) => {
        return (
            <Col span={24}>
                <Form.Item
                    name="template29">
                    <Row gutter={[8, 8]} className='py-1'>
                        <Col xs={24} lg={7}>
                            < div className='font-bold' dangerouslySetInnerHTML={{ __html: label }} />
                        </Col>
                        <Col xs={24} lg={17}>
                            <TextArea
                                className={`w-full`}
                                status={data?.is_edited ? "error" : null}
                                readOnly={true}
                                autoSize={{ minRows: 6 }}
                                value={data.client_ans} />
                        </Col>
                    </Row>
                </Form.Item>
            </Col>
        );
    };

    // YouTube video template
    const templateYouTube = (contentLeft) => {
        return (
            <div className='text-center py-2'>
                <Button type="link" className='text-lg font-semibold' href={contentLeft} target="_blank">{contentLeft}</Button>
            </div>
        );
    };

    const templateVideoStreaming = (src) => {
        return (<div className="grid place-content-center place-items-center py-3.5">
            <Image
                alt="img"
                className='!justify-self-center !w-1/2'
                preview={false}
                src={src}
            />
        </div>
        )
    };

    const shouldAddPageBreak = (section) => {
        if (section.section_id === "2") {
            return (section.display_order === "1" && parseInt(section.part_id) > 1 && !["18", "29"].includes(section.part_id) || (section.display_order === "9" && section.part_id === "28"));
        } else if (section.section_id === "1") {
            return section.display_order === "1" && parseInt(section.part_id) > 1;
        } else if (section.section_id === "3") {
            return section.display_order === "1" && parseInt(section.part_id) > 1;
        } else if (section.section_id === "4") {
            return (section.display_order === "1" && parseInt(section.part_id) > 1 || (section.display_order === "5" && section.part_id === "10"));
        } else if (section.section_id === "5") {
            return (section.display_order === "1" && parseInt(section.part_id) > 1) || (section.display_order === "5" && section.part_id === "8");
        } else if (section.section_id === "6") {
            return section.display_order === "1" && parseInt(section.part_id) > 1 && !["3"].includes(section.part_id);
        } else if (section.section_id === "7") {
            return (section.display_order === "1" && parseInt(section.part_id) > 1) && !(section.display_order === "1" && section.part_id === "15" && section.section_type === "1");
        } 
        // else if (section.section_id === "8") {
            // return section.display_order === "1" && parseInt(section.part_id) > 1;
        // } 
        else {
            return (
                (section.section_id === "0" && (["745", "913", "885", "859"].includes(section.item_id) && ["5", "10", "14", "18"].includes(section.part_id)))
            );
        }
    }
    const renderTemplate = (section) => {
        const addPageBreak = shouldAddPageBreak(section);

        return (
            <>
                {(section.display_order === "1" && parseInt(section.part_id) > 1) && <div className="mt-5"></div>}
                {addPageBreak && <div className="html2pdf__page-break"></div>}
                {!agreementValue && sectionId !== "0" && sectionId !== "8" ? (section.part_id > 1 && (section.display_order === "1" && <i className='block mt-3'>Page {section.part_id}</i>)) : null}
                {selectTemplate(section)}
            </>
        );
    };

    const selectTemplate = (section) => {
        switch (section.template_id) {
            case '0':
                return template0(section.title, section.option_msg, section.scale_msg);

            case '1':
                return template1(section.content_left, section.type_left);

            case '2':
                if (section.item_id === '599') {
                    return (
                        <>
                            <div className="mt-8"></div>
                            {template2(section.title, section.content_left, section.option_msg, section.scale_msg)}
                        </>
                    );
                } else if (section.item_id === '1037' || section.item_id === '433' || section.item_id === '437' || section.item_id === '298' || section.item_id === '319' || section.item_id === '272') {
                    return (
                        <>
                            {template2(section.title, section.content_left, section.option_msg, section.scale_msg)}
                            <div className="mb-2"></div>
                        </>
                    );
                } else {
                    return template2(section.title, section.content_left, section.option_msg, section.scale_msg);
                }

            case '3':
                return template3(section.content_left, section.type_left);

            case '4':
                return template4(section.content_left, section.content_right, section.type_left);

            case '5':
                return template5(section.content_left);

            case '6':
                return template6(section.content_left, section.title, section.video_url);

            case '7':
                if (section.item_id === '1036') {
                    return (
                        <>
                            <div className="mt-3"></div>
                            {template7(section.content_left)}
                        </>
                    );
                } else {
                    return template7(section.content_left);
                }

            case '8':
                return template8(section.content_left);

            case '9':
                return template9(section.stress_chart_result && section.stress_chart_result.length > 0 ? section.stress_chart_result : 0);

            case '10':
                return template10(section.content_left);

            case '11':
                return template11(section.content_left, section.content_right, section.type_left, section.type_right);

            case '12':
                return template12(section.content_left);

            case '13':
                return template13(section.content_left, section);

            case '14':
                return template14(section.content_left);

            case '15':
                return template15(section);

            case '16':
                return template16(section.content_left, section.type_left);

            case '17':
                return template17(section.content_left, section.content_right, section.type_left);

            case '18':
                return template18(section);

            case '19':
                return (
                    <div>
                        {section.item_id === "255" ? (
                            <>
                                <div className='mt-4'></div>
                                {template19(section.content_left)}
                            </>
                        ) : (
                            template19(section.content_left)
                        )}
                    </div>
                );

            case '20':
                return (
                    <>
                        <div className='mt-4'></div>
                        {template20()}
                    </>
                );

            case '21':
                return (
                    <div className='mx-6'>
                        {luscherColorTest(boxes, null, section.item_id, null, null, null, null)}
                    </div>
                )

            case '23':
                if (agreementValue && agreementValue.showcost === 0) {
                    return null;
                }
                return template23(section.content_left, agreementValue ? (agreementValue.currency === "0" ? "USD ($)" : "INR (₹)") + " " + (agreementValue.amount || "") : "INR (₹) ");

            case '24':
                return checkboxGrid(section.content_left);

            case '26':
                return template26(section.content_left);

            case '27':
                if (agreementValue && agreementValue.showcost === 0) {
                    return null;
                }
                return template27(section.content_left, agreementValue ? (agreementValue.currency === "0" ? "USD ($)" : "INR (₹)") + " " + (agreementValue.totalamount || "") + " per " + (agreementValue.session + " sessions") : "INR (₹) ");

            case '28':
                return agreementValue?.notes === null ? null : template28(agreementValue?.notes);

            case '29':
                return template29(section.content_left, section);

            case '31':
                return templateVideoStreaming(section.content_right);
            case '32':
                return templateYouTube(section?.content_left);

            default:
                return null;
        }
    };

    return (
        <Spin spinning={loader} size="large">
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>
                {constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">
                    {agreementValue ? constants.CoachingAgreement : docName === constants.DocumentList ? constants.RefresherWorkSheet : docName}
                </span>
            </div>
            <div className='container mx-auto'>
                <div className='mx-2 md:mx-6 md:mt-8'>
                    <Card className='lg:w-5/6 self-end mx-auto'>
                        {!agreementValue ? <div className='flex justify-between'>
                            {history.length > 1 && <Button className='bg-sky-800 text-white uppercase' onClick={() => history.goBack()}>{constants.BackBtn}</Button>}
                            <Button disabled={sectionData?.length < 1} className='bg-sky-800 text-white uppercase downloadPdf'>{constants.DownloadPDF}</Button>
                        </div> : null}
                        <h2 className='text-2xl text-center mt-2 font-semibold border-b pb-3'>{agreementValue ? constants.CoachingAgreement : docName}</h2>
                        {sectionId !== "2" ? <div id='downloadContent' className='container mx-auto my-2 px-2'>
                            {!agreementValue ?
                                <>
                                    {sectionId !== "0" && sectionId !== "8" && sectionData.length > 0 ? <i>Page 1</i> : null}
                                    <div className='mt-3' style={{ backgroundColor: '#075073' }}><img alt="RoyCares & Associates" src={Logo} width={360} /></div>
                                    <div className='text-center bg-amber-300 font-bold xl:h-14 h-18 p-1.5' style={{ fontSize: '22px' }}>{constants.Subtitle}</div>
                                </> : null}
                            {sectionId === "0" ? (
                                null
                            ) : (
                                <h3 className='font-bold text-center text-xl my-3'>{sectionName}</h3>
                            )}
                            {sectionId === 7 && (sectionType === "1" || sectionType === "0") ? (
                                sectionData
                                    .filter((section) => section.section_type === sectionType)
                                    .map((section) => (
                                        <div key={section.id || Math.random()} className='!text-lg repeatData'>
                                            {renderTemplate(section)}
                                        </div>
                                    ))
                            ) : (
                                sectionData.map((section) => (
                                    <div key={section.id || Math.random()} className='!text-lg repeatData'>
                                        {renderTemplate(section)}
                                    </div>
                                ))
                            )}
                            {!agreementValue ? <div className='mt-8 h-20 text-center text-white text-xl bg-sky-800 font-light'>
                                <div>{constants.AnyoneRecover}</div>
                                <div><i><span>Renew</span> • <span>Restart</span> • <span>Reset</span> • <span>Refocus</span></i></div>
                            </div> : null}
                        </div>
                            : <>
                                <div id='group1' className='container mx-auto my-2 px-2'>
                                    {sectionId !== "0" && sectionData.length > 0 ? <i>Page 1</i> : null}
                                    <div className='mt-3' style={{ backgroundColor: '#075073' }}><img alt="RoyCares & Associates" src={Logo} width={360} /></div>
                                    <div className='text-center bg-amber-300 font-bold xl:h-14 h-18 p-1.5' style={{ fontSize: '22px' }}>{constants.Subtitle}</div>
                                    <h3 className='font-bold text-center text-xl my-3'>{sectionName}</h3>
                                    {sectionData
                                        .filter((section) => section.part_id <= 17)
                                        .map((section) => {
                                            return (
                                                <div key={section.id || Math.random()} className='!text-lg repeatData'>
                                                    {renderTemplate(section)}
                                                </div>
                                            );
                                        })}
                                </div>
                                <div id='group2' className='container mx-auto my-2 px-2'>
                                    {sectionData
                                        .filter((section) => section.part_id >= 18 && section.part_id <= 28)
                                        .map((section) => {
                                            return (
                                                <div key={section.id || Math.random()} className='!text-lg repeatData'>
                                                    {renderTemplate(section)}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div id='group3' className='container mx-auto my-2 px-2'>
                                    {sectionData
                                        .filter((section) => section.part_id >= 29)
                                        .map((section) => {
                                            return (
                                                <div key={section.id || Math.random()} className='!text-lg repeatData'>
                                                    {renderTemplate(section)}
                                                </div>
                                            );
                                        })
                                    }
                                    <div className='mt-8 h-20 text-center text-white text-xl bg-sky-800 font-light'>
                                        <div>{constants.AnyoneRecover}</div>
                                        <div><i><span>Renew</span> • <span>Restart</span> • <span>Reset</span> • <span>Refocus</span></i></div>
                                    </div>
                                </div>
                            </>}
                        {!agreementValue ? <div className='flex justify-between py-3'>
                            {history.length > 1 && <Button className='bg-sky-800 text-white uppercase' onClick={() => history.goBack()}>{constants.BackBtn}</Button>}
                            <Button disabled={sectionData?.length < 1} className='bg-sky-800 text-white uppercase downloadPdf'>{constants.DownloadPDF}</Button>
                        </div> : null}
                    </Card>
                </div>
            </div>
        </Spin>
    );
};

export default MasterWbList;
